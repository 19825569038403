<template>
  <div>
    <section class="bg-dark text-light">
      <div class="container">
        <div class="row py-5">
          <div class="col-sm-6 col-md-6">
            <h1 class="display-1 text-uppercase fw-bolder text-secondary"><span class="text-light">Corporate Websites</span> <br><span class="">for businesses nationwide</span></h1>
          </div>
          <div class="col-sm-6 col-md-6">
            <img src="../assets/web.jpg" alt="Web design and digital marketing" class="w-100 d-none d-md-block">
          </div>
        </div>
      </div>
    </section>

    <section class="bg-black text-light">
      <div class="container">
        <div class="row py-5">
          <div class="col-sm-12">
            <h3 class="display-5 text-uppercase text-secondary fw-bolder mb-5">
              Services
            </h3>
          </div>
          <div class="col-sm-4">
            <table class="table table-dark table-striped border-dark text-light text-center">
              <thead>
                  <tr>
                      <th><img src="../assets/smpost.jpg" width="100%" title="Social media post" /></th>
                  </tr>
              </thead>
              <tbody>        
                  <tr>
                    <th class="text-light"><span class="text-uppercase">Social media post</span><br>
                      <small class="text-secondary">We create eye-catching social media post for your brand</small></th>
                  </tr>
                  <tr>
                    <td>2 days delivery</td>
                  </tr>
                  <tr>
                    <td>1 revision</td>
                  </tr>
                  <tr>
                    <td>Single-sided</td>
                  </tr>
                  <tr>
                    <td>100% satisfaction guarantee</td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-4">
            <table class="table table-dark table-striped border-dark text-light text-center">
              <thead>
                  <tr>
                      <th><img src="../assets/htmlfix.jpg" width="100%" title="HTML error fix" /></th>
                  </tr>
              </thead>
              <tbody>        
                  <tr>
                    <th class="text-light"><span class="text-uppercase">HTML & CSS fixes</span><br>
                      <small class="text-secondary">We fix HTML and CSS errors on your business website</small></th>
                  </tr>
                  <tr>
                    <td>3 days delivery</td>
                  </tr>
                  <tr>
                    <td>1 revision</td>
                  </tr>
                  <tr>
                    <td>Fix responsive design issues</td>
                  </tr>
                  <tr>
                    <td>Fix Browser compatibility issues</td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div class="col-sm-4">
            <table class="table table-dark table-striped border-dark text-light text-center">
              <thead>
                  <tr>
                      <th><img src="../assets/wordpress.jpg" width="100%" title="Wordpress usiness website" /></th>
                  </tr>
              </thead>
              <tbody>        
                  <tr>
                    <th class="text-light"><span class="text-uppercase">Business website</span><br>
                      <small class="text-secondary">We create corporate 5-page static website</small></th>
                  </tr>
                  <tr>
                    <td>2 weeks delivery</td>
                  </tr>
                  <tr>
                    <td>1 revision</td>
                  </tr>
                  <tr>
                    <td>WordPress CMS</td>
                  </tr>
                  <tr>
                    <td>Responsive pages</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
    
    <section class="bg-dark text-light">
      <div class="container">
        <div class="row py-5">
          <div class="col-sm-8 offset-sm-2 text-center fs-3">
            <h3 class="display-5 text-uppercase text-secondary fw-bolder mb-5">London-based digital agency</h3>
            <p>We're a digital agency based in London. We specialise in website design and development and digital marketing services for service-based businesses.</p>

            <p>We're a team of friendly, plain speaking and talented experts. Our team of designers and developers are passionate about growing our clients' businesses online.</p>

            <a href="mailto:leocorplondon@gmail.com?subject=Regarding services - " class="btn btn-outline-secondary btn-lg fw-bolder mt-3 text-uppercase">Contact us</a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style>
  img {
    filter: grayscale(100%);
  }
</style>
