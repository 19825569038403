import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView'
import PaymentView from '../views/PaymentView'
import TermsView from '../views/TermsView'

const routes = [
  { path: '/', component: HomeView },
  { path: '/payment', component: PaymentView },
  { path: '/terms', component: TermsView },
  { path:'/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router