<template>
  <section class="bg-dark text-secondary">
    <div class="container">
      <div class="row py-5">
        <div class="col-sm-12">
          <h3 class="display-5 text-uppercase text-secondary fw-bolder mb-5">Terms</h3>
          <article>
            <h4>PRIVACY STATEMENT</h4>

            <p>LEOCORP LIMITED is committed to protecting your privacy and maintaining the security of any personal information received from you. We strictly adhere to the requirements of the data protection legislation in the UK.</p>

            <ol>
              <li>When you order, we need to know your name, address, email address and card details. This allows us to process and fulfill your order. You have the option to withhold personal information that is not required for the order Process.</li>
              <li>We use your personal information to update you about new products/services.</li>
              <li>We will only contact you with your consent.</li>
              <li>We do not sell, rent or exchange your personal information with any third party for commercial reasons, beyond the essential requirement for credit/debit card validation during purchase.</li>
              <li>In order to process credit/debit card transactions, the bank or card processing agency may require verifying your personal details for authorisation outside the EEA (European Economic Area). Your information will not be transferred outside the EEA for any other purpose.</li>
              <li>We use a technology called "cookies" as part of a normal business procedure to track patterns of behaviour of visitors to our site. A cookie is an element of data that our Website sends to your browser which is then stored on your system. You can set your browser to prevent this happening. Any information collected in this way can be used to identify you unless you change your browser settings.</li>
              <li>We do not collect sensitive information about you except when you specifically knowingly provide it. In order to maintain the accuracy of our database, you can check, update or remove your personal details by clicking the unsubscribe link which is present in every email we will send to you.</li>
              <li>We will not transfer your information outside the EEA (European Economic Area) without first obtaining your consent.</li>
            </ol>

            <p>We follow strict security procedures in the storage and disclosure of information which you have given us, to prevent unauthorised access in accordance with the UK data protection legislation.</p>
            <p>If you have any questions about privacy please email:&nbsp;support@leocorp.com</p>

            <hr>
          </article>
          <article>
            <h4>TERMS AND CONDITIONS - STANDARD TERMS</h4>

            <p>'The Company' is LEOCORP LIMITED. ‘The Client' means the party, or any person acting on their behalf with whom the Company contracts.</p>
            <p>These are the standard terms and conditions for Website Design and Development and apply to all contracts and all work undertaken by LEOCORP for its clients.</p>
            <p class="flow-text">Fees and deposits</p>
            <p>A 50% deposit of the total fee payable under our proposal is due immediately upon you instructing us to proceed with the website design and development work. The remaining 50% shall become due when the work is completed to your reasonable satisfaction but subject to the terms of the "approval of work" and "rejected work" clauses. We reserve the right not to commence any work until the deposit has been paid in full.</p>
            <p>The 50% deposit is only refundable if we have not fulfilled our obligations to deliver the work required under the agreement. The deposit is not refundable if the development work has been started and you terminate the contract through no fault of ours.</p>
            <p class="flow-text">Supply of materials</p>
            <p>You must supply all materials and information required by us to complete the work in accordance with any agreed specification. Such materials may include, but are not limited to, photographs, written copy, logos and other printed material. Where there is any delay in supplying these materials to us which leads to a delay in the completion of work, we have the right to extend any previously agreed deadlines by a reasonable amount.</p>
            <p>Where you fail to supply materials, and that prevents the progress of the work, we have the right to invoice you for any part or parts of the work already completed.</p>
            <p class="flow-text">Variations</p>
            <p>We are pleased to offer you the opportunity to make revisions to the design. However, we have the right to limit the number of design proposals to a reasonable amount and may charge for additional designs if you make a change to the original design specification.</p>
            <p>Our website development phase is flexible and allows certain variations to the original specification. However any major deviation from the specification will be charged separately.</p>
            <p class="flow-text">Project delays and client liability</p>
            <p>Any time frames or estimates that we give are contingent upon your full co-operation and complete and final content in photography for the work pages. During development there is a certain amount of feedback required in order to progress to subsequent phases. It is required that a single point of contact be appointed from your side and be made available on a daily basis in order to expedite the feedback process.</p>
            <p class="flow-text">Approval of work</p>
            <p>On completion of the work you will be notified and have the opportunity to review it. You must notify us in writing of any unsatisfactory points within 7 days of such notification. Any of the work which has not been reported in writing to us as unsatisfactory within the 7-day review period will be deemed to have been approved. Once approved, or deemed approved, work cannot subsequently be rejected and the contract will be deemed to have been completed and the 50% balance of the project price will become due.</p>
            <p class="flow-text">Rejected work</p>
            <p>If you reject any of our work within the 7-day review period, or not approve subsequent work performed by us to remedy any points recorded as being unsatisfactory, and we, acting reasonably, consider that you have been unreasonable in any rejection of the work, we can elect to treat this contract as at an end and take measures to recover payment for the completed work.</p>
            <p class="flow-text">Payment</p>
            <p>Upon completion of the 7-day review period, we will invoice you for the 50% balance of the project.</p>
            <p class="flow-text">Warranty by the client as to ownership of intellectual property rights</p>
            <p>You must obtain all necessary permissions and authorities in respect of the use of all copy, graphic images, registered company logos, names and trade marks, or any other material that you supply to us to include in your website or web applications.</p>
            <p>It is the responsibility of the Client that all materials (including, but not limited to images, diagrams, logos, videos, data, as well as intellectual property in other media) supplied to LEOCORP by the client will have the relevant copyrights, licenses and permissions for use in the commissioned project. LEOCORP will not accept responsibility/liability for infringements caused by any wrongly supplied materials.</p>
            <p>You must indemnify us and hold us harmless from any claims or legal actions related to the content of your website.</p>
            <p class="flow-text">Licensing</p>
            <p>Once you have paid us in full for our work we grant to you a license to use the website and its related software and contents for the life of the website.</p>
            <p class="flow-text">Search engines</p>
            <p>We do not guarantee any specific position in search engine results for your website. We perform basic search engine optimisation according to current best practice.</p>
            <p class="flow-text">Consequential loss</p>
            <p>We shall not be liable for any loss or damage which you may suffer which is in any way attributable to any delay in performance or completion of our contract, however that delay arises.</p>
            <p class="flow-text">Disclaimer</p>
            <p>To the full extent permitted by law, all terms, conditions, warranties, undertakings, inducements or representations whether express, implied, statutory or otherwise (other than the express provisions of these terms and conditions) relating in any way to the services we provide to you are excluded. Without limiting the above, to the extent permitted by law, any liability of LEOCORP under any term, condition, warranty or representation that by law cannot be excluded is, where permitted by law, limited at our option to the replacement, re-repair or re-supply of the services or the payment of the cost of the services that we were contracted to perform.</p>
            <p class="flow-text">Subcontracting</p>
            <p>We reserve the right to subcontract any services that we have agreed to perform for you as we see fit.</p>
            <p class="flow-text">Non-disclosure</p>
            <p>We (and any subcontractors we engage) agree that we will not at any time disclose any of your confidential information to any third party.</p>
            <p class="flow-text">Additional expenses</p>
            <p>You agree to reimburse us for any requested expenses which do not form part of our proposal including but not limited to the purchase of templates, third party software, stock photographs, fonts, domain name registration, web hosting or comparable expenses.</p>
            <p class="flow-text">Backups</p>
            <p>You are responsible for maintaining your own backups with respect to your website and we will not be liable for restoring any client data or client websites except to the extent that such data loss arises out of a negligent act or omission by us.</p>
            <p class="flow-text">Ownership of domain names and web hosting</p>
            <p>We will supply to you account credentials for domain name registration and/or web hosting that we purchased on your behalf when you reimburse us for any expenses that we have incurred.</p>
            <p class="flow-text">Governing law</p>
            <p>The agreement constituted by these terms and conditions and any proposal will be construed according to and is governed by the laws of Tasmania. You and LEOCORP submit to the non-exclusive jurisdiction of the courts in and of Tasmania in relation to any dispute arising under these terms and conditions or in relation to any services we perform for you.</p>
            <p class="flow-text">Cross browser compatibility</p>
            <p>By using current versions of well supported content management systems such as "Joomla", we endeavour to ensure that the web sites we create are compatible with all current modern web browsers such as the most recent versions of Internet Explorer, Firefox, Google Chrome and Safari. Third party extensions, where used, may not have the same level of support for all browsers. Where appropriate we will substitute alternative extensions or implement other solutions, on a best effort basis, where any incompatibilities are found.</p>
            <p class="flow-text">E-commerce</p>
            <p>You are responsible for complying with all relevant laws relating to e-commerce, and to the full extent permitted by law will hold harmless, protect, and defend and indemnify LEOCORP and its subcontractors from any claim, penalty, tax, tariff loss or damage arising from your or your clients' use of Internet electronic commerce.</p>

            <hr>
          </article>
          <article>
            <h4>TERMS AND CONDITIONS - CLIENT PROJECT PROPOSAL CONTRACT</h4>

            <ol>
              <li>DEFINITIONS
                <ol>
                  <li><b>Agreement</b> means the Project Proposal, Terms and Conditions and any other attached documents.</li>
                  <li><b>Project</b> means the scope and purpose of Client's identified usage of the work product as described in the Project Proposal.</li>
                  <li><b>Services</b> means all services and the work product to be provided to Client by Company as described and otherwise further defined in the Project Proposal.</li>
                  <li><b>Final Deliverables</b> means the final versions of Deliverables provided by Company and accepted by Client.</li>
                  <li><b>Deliverables</b> means the services and work product specified in the Project Proposal to be delivered by Company to Client.</li>
                  <li><b>Client Content</b> means all materials, writing, images or other creative content provided by Client used in preparing or creating the Deliverables.</li>
                  <li><b>Third Party Materials</b> means proprietary third party materials which are incorporated into the Final Deliverables, including without limitation stock photography or illustration.</li>
                  <li><b>Company Tools</b> means all design/development tools developed and/or used by Company in performing the Services, including pre-existing and newly developed software including source code, Web authoring tools, type fonts, and application tools, together with any other software, or other inventions whether or not patentable, and general non-copyrightable concepts such as website design, architecture, layout, navigational and functional elements.</li>
                </ol>
              </li>
              <li>COMPANY SERVICES<br>Company shall perform the services listed in the proposal.</li>
              <li>PROPOSAL<br>The terms of this Agreement expires thirty (30) days after being submitted to Client. If this Agreement expires, Company may modify the Agreement and re-submit it to Client.</li>
              <li>COMPENSATION
                <ol>
                  <li><b>Fees</b>: Client agrees to pay Company the fees listed in the Project Proposal, including all taxes.</li>
                  <li><b>Expenses</b>: Client will pay Company expenses, including but not limited to: (a) Incidental and out-of-pocket expenses at cost plus Company's standard markup of fifteen (15%); (b) Travel expenses, other than normal commuting, but including airfare and rental vehicles, with client approval.</li>
                  <li><b>Additional Costs</b>: Pricing in the Project Proposal includes only Company fees. Any other costs, such as hosting, art licensing or photography, will be billed to Client.</li>
                  <li><b>Hosting Final Deliverables</b>: Company will host the Final Deliverables on Company's web space after Client has made full payment.</li>
                </ol>
              </li>
              <li>PAYMENT
                <ol>
                  <li><b>Payment Schedule</b>: Fifty percent (50%) payment is due upon project commencement, remaining upon project completion. Company agrees that it is not entitled to any further fees from Client in relation to this project unless otherwise agreed in writing by the Client.</li>
                  <li><b>Invoices</b>: All invoices are payable within fifteen (15) days of receipt. Invoices shall list any expenses and additional costs as separate items. Company will invoice Client at the beginning or end of the project.</li>
                </ol>
              </li>
              <li>LATE PAYMENT
                <ol>
                  <li><b>Late Fee</b>: A monthly service fee of 1.5 percent, or the maximum allowed by law, is payable on all overdue balances.</li>
                  <li><b>Crediting Late Payments</b>: Payments will be credited to late payments first, then to unpaid balances.</li>
                  <li><b>Collection Expenses</b>: Client shall pay all collection or legal fees caused by late payments.</li>
                  <li><b>Withholding Delivery</b>: Company may withhold delivery and transfer of ownership of any current work if accounts are not current or overdue invoices are not paid in full.</li>
                  <li><b>Withholding License</b>: All grants of any license to use or transfer ownership of any intellectual property rights under this Agreement are conditioned on full payment, including all outstanding Additional Costs, Expenses, Fees, or any other charges.</li>
                </ol>
              </li>
              <li>CHANGES TO PROJECT SCOPE
                <ol>
                  <li><b>Change Request</b>: If Client wants to change the scope of work after acceptance of this Agreement, Client shall send Company a written Change Order describing the requested changes in detail. Within five (5) days of receiving a Change Order, Company will respond with a statement proposing Company's availability, additional fees, changes to delivery dates, and any modification to the Terms and Conditions. Company will evaluate each Change Order at its standard rate and charges.</li>
                  <li><b>Major Change</b>: If Client requests are at or near ten (10%) percent of the time required to produce Deliverables, or the value of the scope of services, Company shall be entitled to submit a new and separate Proposal to Client for written approval. Company shall not begin work on the revised services until it receives a fully signed revised proposal and any additional fees.</li>
                  <li><b>Minor Change</b>: If Client requests are not Major Changes, Client will be billed on a time and materials basis at Company's hourly rate of £50 (GBP) per hour. Such charges shall be in addition to all other amount payable under this Agreement, despite any maximum budget, contract price or final price identified. Company may extend or modify any delivery schedule or deadlines in the Agreement as may be required by such changes.</li>
                  <li><b>Acceptance/Rejection</b>: Client will have five (5) days to respond in writing accepting or rejecting the new proposal. If Client rejects the proposal, Company will not be obligated to perform any services beyond those in the original Agreement.</li>
                </ol>
              </li>
              <li>DELAYS
                <ol>
                  <li><b>Company Delays</b>: Company shall use all reasonable efforts to meet the work plan and milestones delivery schedule. Company may extend the due date for any Deliverable by giving written notice to Client. The total of all extensions shall not exceed Fourteen (14) days.</li>
                  <li><b>Client Delays</b>: Client shall use all reasonable efforts to provide needed information, materials and approvals. Any delay by Client will result in a day-for-day extension of the due date for all Deliverables.</li>
                  <li><b>General Delays</b>: Any delay caused by conditions beyond the reasonable control of the parties shall not be considered a breach and will result in a day-for-day extension any performance due. Each party shall use reasonable efforts to notify the other party, in writing, of a delay. Conditions beyond the reasonable control of the parties include, but are not limited to, natural disasters, acts of government after the date of agreement, power failure, fire, flood, acts of God, labor disputes, riots, acts of war, terrorism and epidemics.</li>
                </ol>
              </li>
              <li>EVALUATION AND ACCEPTANCE
                <ol>
                  <li><b>Testing</b>: Company will test and correct Deliverables using commercially reasonable efforts before providing Deliverables to Client.</li>
                  <li><b>Approval Periods</b>: Client shall, within five (5) business days after receiving each Deliverable, notify Company in writing of any failure to comply with the specification of the Project Proposal or of any other objections, corrections or changes required. Company shall, within five (5) business days of receiving Client's notification, correct and submit a revised Deliverable to Client. Client shall, within five (5) business days of receiving a revised Deliverable, either approve the corrected version or make further changes. If after three (3) corrections by Company, Client finds the Deliverables are not acceptable, Client may terminate this agreement subject to the termination clauses of this Agreement. If Client fails to provide approval or comments during any approval period, those Deliverables will be considered approved and accepted. All objections, corrections and changes shall be subject to the terms and conditions of this Agreement.</li>
                </ol>
              </li>
              <li>CLIENT RESPONSIBILITIES<br>Client acknowledges that it is responsible for performing the following in a reasonable and timely manner: (a) Provide Client Content in a form suitable for use in the Deliverables without further preparation by Company, unless otherwise specified in the Project Proposal; (b) Proofread all Deliverables. Client will be charged for correcting errors after the acceptance of any Deliverable; (c) Make decisions regarding other parties.</li>
              <li>ACCREDITATION AND PROMOTION
                <ol>
                  <li><b>Accreditation</b>: Company shall be entitled to place accreditation (credit line), as a hyperlink or otherwise, in the form, size and location as incorporated by Company in the Deliverables on each page of the Final Deliverables. Client agrees to pay an additional fifty percent (50%) of the total fee, excluding expenses, for failure to include credit line.</li>
                  <li><b>Promotion</b>: Company retains the right to reproduce, publish and display the Deliverables in Company's portfolios and websites, in galleries, design periodicals and other media or exhibits for the purposes of recognition of creative excellence or professional advancement, and to be credited with authorship of the Deliverables in connection with such uses.</li>
                  <li><b>Promotional Approval</b>: Either party, subject to the other's reasonable approval, may describe its role in the Project on its website and in other promotional and marketing materials, and, if not expressly objected to, include a link to the other party's website.</li>
                </ol>
              </li>
              <li>CONFIDENTIAL INFORMATION<br>Client's "Confidential Information" includes information that Company should reasonably believe to be confidential. Company's "Confidential Information" includes the source code of any Company Tools. All material considered confidential by either party shall be designated as confidential. Confidential Information shall not be disclosed to third parties and shall only used as needed to perform this Agreement. Confidential Information shall not include any information that is already known by the recipient, becomes publicly known through no fault of the recipient, or is received from a third party without a restriction on disclosure.</li>
              <li>RELATIONSHIP OF THE PARTIES
                <ol>
                  <li><b>Independent Contractor</b>: Company is an independent contractor. Company shall determine, in its sole discretion, the manner and means by which the Services are accomplished. No agency, partnership, joint venture, or employee-employer relationship is intended or created by this Agreement. Neither party is authorised to act as agent or bind the other party except as expressly stated in this Agreement. Company and the work product or Deliverables prepared by Company shall not be deemed a work for hire as defined under Copyright Law. All rights granted to Client are contractual in nature and are expressly defined by this Agreement.</li>
                  <li><b>Design Agents</b>: Company shall be allowed to use third parties as independent contractors in connection with the Services ("Design Agents"). Company shall remain fully responsible for Design Agents' compliance with this Agreement.</li>
                  <li><b>No Exclusivity</b>: This Agreement does not create an exclusive relationship between the parties. Client is free to engage others to perform services of the same or similar nature to those provided by Company, and Company shall be entitled to offer and provide design services to others, solicit other clients and otherwise advertise the services offered by Company.</li>
                </ol>
              </li>
              <li>REPRESENTATIONS AND WARRANTIES
                <ol>
                  <li><b>By Client</b>: Client represents and warrants to Company that: (a) To the best of Client’s knowledge, use of Client Content does not infringe the rights of any third party; (b) Client shall comply with the terms and conditions of any licensing agreements which govern the use of Third Party Materials; (c) Client will obtain all necessary and appropriate rights and licenses to grant license to Company to use Third Party Materials.</li>
                  <li><b>By Company</b>: Company represents and warrants to Client that: (a) Company will provide the Services identified in the Agreement in a professional and workmanlike manner; (b) Company shall secure all necessary rights, title, and interest in and to the Final Deliverables, including Company Tools, sufficient for Company to grant the intellectual property rights provided in this Agreement; (c) To the best of Company’s knowledge, the Deliverables will not violate the rights of any third parties; (d) If Client or third parties modify the Deliverables or use the Deliverables outside of the scope or purpose of this Agreement, all representations and warranties of Company shall be void.</li>
                  <li>EXCEPT FOR THE EXPRESS REPRESENTATIONS AND WARRANTIES STATED IN THIS AGREEMENT, COMPANY MAKES NO WARRANTIES WHATSOEVER. COMPANY EXPLICITLY DISCLAIMS ANY OTHER WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR COMPLIANCE WITH LAWS OR GOVERNMENT RULES OR REGULATIONS APPLICABLE TO THE PROJECT.</li>
                </ol>
              </li>
              <li>INDEMNIFICATION AND LIABILITY
                <ol>
                  <li><b>By Client</b>: Client shall indemnify Company from any and all damages, liabilities, costs, losses, expenses or attorney fees arising out of any claim, demand, or action by a third party arising out of any breach of Client’s responsibilities or obligations, representations or warranties under this Agreement. Company shall promptly notify Client in writing of any third party claim or suit. Client shall have the right to fully control the defense and any settlement of such claim or suit.</li>
                  <li><b>By Company</b>: In the case of a  third party lawsuit or proceeding based on a claim that Deliverables breach the third party’s intellectual property rights, and it is determined that such infringement has occurred, Company may at its own expense, replace any infringing content with non-infringing content.</li>
                  <li><b>Limitation of Liability</b>: THE SERVICES AND THE WORK PRODUCT OF COMPANY ARE SOLD “AS IS.” IN ALL CIRCUMSTANCES, THE MAXIMUM LIABILITY OF COMPANY, ITS DIRECTORS, OFFICERS, EMPLOYEES, DESIGN AGENTS AND AFFILIATES (“COMPANY PARTIES”), TO CLIENT FOR DAMAGES FOR ANY AND ALL CAUSES WHATSOEVER, AND CLIENT’S MAXIMUM REMEDY, REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR OTHERWISE, SHALL BE LIMITED TO THE NET PROFIT OF COMPANY. IN NO EVENT SHALL COMPANY BE LIABLE FOR ANY LOST DATA OR CONTENT, LOST PROFITS, BUSINESS INTERRUPTION OR FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO THE MATERIALS OR THE SERVICES PROVIDED BY COMPANY, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</li>
                </ol>
              </li>
              <li>TERM AND TERMINATION
                <ol>
                  <li><b>Term</b>: This agreement shall begin when both parties sign and shall continue until all Services are complete and delivered, or until the Agreement is Terminated.</li>
                  <li><b>Termination for Cause</b>: Either party may terminate this agreement at any time, on thirty (30) days prior written notice if the other party breaches any of its material responsibilities or obligations under this Agreement and fails to cure that breach during that thirty (30) day period.</li>
                  <li><b>Termination for Insolvency</b>: Either party may terminate this agreement at any time, on written notice to the other party, if the other party ceases to conduct business in its normal course; makes an assignment for the benefit of creditors; is liquidated or otherwise dissolved; becomes insolvent; files a petition in bankruptcy; or a receiver, trustee, or custodian is appointed for it.</li>
                  <li><b>Termination by Mutual Agreement</b>: This agreement may be terminated by the mutual agreement of the parties.</li>
                  <li><b>Termination for Convenience</b>: Either party may terminate this agreement at any time and for any reason on thirty (30) days prior written notice to the other party. If Client terminates the Agreement under this section, Company shall, at Client's reasonable discretion, complete any work assigned or scheduled during the notice period in accordance with the terms and conditions of this Agreement.</li>
                  <li><b>Termination Fees</b>: In the event of termination, Client shall pay Company for the Services performed through the date of termination in the amount of a prorated portion of the fees due. Client shall pay all Expenses, Fees, and Additional Costs incurred through the date of termination.</li>
                  <li><b>Intellectual Property</b>: If Client terminates and on full payment of compensation, Company grants Client right and title as provided by this Agreement with respect to those Deliverables provided and accepted by Client as of the date of termination.</li>
                  <li><b>Confidential Information</b>: On expiration or termination of this Agreement: (a) each party shall return or, at the disclosing party’s request, destroy the Confidential Information of the other party, and (b) all rights and obligations regarding Confidential Information shall survive.</li>
                </ol>
              </li>
              <li>RIGHTS TO FINAL ART
                <ol>
                  <li><b>License</b>: Company grants to Client a non-exclusive, perpetual and worldwide  license to use and display the Final Deliverables in accordance with this Agreement. The rights granted to Client are for use of the Final Deliverables in its original form only. Client may not change, create derivative works or extract portions of the Final Deliverables.</li>
                  <li><b>Liquidation for unlicensed use</b>: Additional use of any Deliverables by Client outside the scope of the license granted above requires additional fees. Company shall be entitled to further compensation equal to fifty (50%) percent of the total original Project fee unless otherwise agreed in writing by both parties. In the event of nonpayment, Company shall be entitled to pursue all remedies under law and equity.</li>
                </ol>
              </li>
              <li>RIGHTS TO DELIVERABLES OTHER THAN FINAL ART
                <ol>
                  <li><b>Client Content</b>: Client Content is the exclusive property of Client. Client grants Company a nonexclusive, nontransferable license to use, reproduce, modify, display and publish Client Content solely in connection with Company’s performance of the Services and limited promotional uses of the Deliverables as authorised in this Agreement.</li>
                  <li><b>Preliminary Works</b>: Company retains all rights in and to all Preliminary Works. Client shall return all Preliminary Works to Company within thirty (30) days of completion of the Services.</li>
                  <li><b>Company Tools</b>: All Company Tools are and shall remain the exclusive property of Company. Company grants Client a nonexclusive, nontransferable, perpetual, worldwide license to use Company Tools solely to the extent necessary with the Final Deliverables for the Project.</li>
                </ol>
              </li>
              <li>SUPPORT SERVICES
                <ol>
                  <li><b>Warranty Period</b>: During the first month following expiration of this Agreement, Company shall provide Support Services at no additional cost to Client. Support Services means commercially reasonable technical support and assistance to maintain and update the Deliverables, including correcting any errors or Deficiencies. Requests for additional support will be billed on a time and materials basis at Company's standard rate.</li>
                  <li><b>Maintenance Period</b>: After the Warranty Period expires and at Client’s option, Company will provide Support Services for Company’s hourly fees of £50 (GBP) per hour subject to Client’s reasonable approval.</li>
                  <li><b>No Enhancements</b>: The services in the Warranty Period and the Maintenance Period do not include enhancements to the Project or other services outside the scope of the Proposal.</li>
                </ol>
              </li>
              <li>ENHANCEMENTS<br>During the Maintenance Period, Client may request that Company develop enhancements to the Deliverables. Company shall exercise commercially reasonable efforts to prioritise Company’s resources to create such enhancements. Client understands Company may have pre existing obligations that may delay requested enhancements. Any enhancements shall be provided on a time and materials basis at Company's standard rate.
              <ol>
                <li><b>Alterations</b>: Alteration of any Deliverable is prohibited without the express permission of Company. Company will be given the first opportunity to make the required alterations. Unauthorised alterations shall constitute additional use and will be billed accordingly.</li>
              </ol>
            </li>
              <li>DISPUTE RESOLUTION
                <ol>
                  <li><b>Negotiation</b>: Parties agree to attempt to resolve any dispute by negotiation between the parties.</li>
                  <li><b>Arbitration/Mediation</b>: If parties are unable to resolve the dispute by negotiation, either party may start mediation and/or binding arbitration in a forum mutually agreed to by the parties.</li>
                  <li><b>Litigation</b>: In all other circumstances, the parties specifically consent to the courts located in the United Kingdom. The parties waive any jurisdictional or venue defenses available to them and further consent to service of process by mail.</li>
                  <li><b>Attorney Fees</b>: The prevailing party shall be entitled to recover its attorney's fees and costs in any dispute resolved by binding arbitration or litigation.</li>
                </ol>
              </li>
              <li>GENERAL
                <ol>
                  <li><b>Modification/Waiver</b>: Modifications to this Agreement must be in writing and signed by both parties. Failure by either party to enforce any right or seek to remedy any breach under this Agreement shall not be construed as a waiver of such rights nor shall a waiver by either party of default in one or more instances be construed as constituting a continuing waiver or as a waiver of any other breach.</li>
                  <li><b>Notices</b>: All notices under this Agreement shall be given in writing either by: (a) Fax or Email, with return confirmation of receipt; (b) Certified or Registered mail, with return receipt requested. Notice will be effective when received, or in the case of email or fax, on confirmation of receipt.</li>
                  <li><b>No Assignment</b>: Rights or obligations under this Agreement shall not be transferred, assigned or encumbered without the prior written consent of the other party.</li>
                  <li><b>Governing Law</b>: This Agreement shall be governed by the law of the United Kingdom.</li>
                  <li><b>Severability</b>: If any provision of this Agreement is held invalid or unenforceable, the remainder of this Agreement shall remain in full force and effect. Where possible the invalid or unenforceable provision shall be interpreted in such manner as to be effective and valid under applicable law.</li>
                  <li><b>Headings</b>: Headings and numbering used in this Agreement are for convenience and reference only and shall not affect the scope, meaning, intent or interpretation of this Agreement, and shall not have any legal effect.</li>
                  <li><b>Complete Agreement</b>: This Agreement along with the terms and conditions on Company’s website (https://www.leocorp.com) is the entire understanding of the parties and supersedes all prior understandings and documents relating to the subject matter of this Agreement.</li>
                </ol>
              </li>
            </ol>

            <hr>
          </article>
          <article>
            <h4>TERMS AND CONDITIONS - GENERAL TERMS OF USE</h4>
            
            <p class="flow-text">Acceptance of Terms</p>
            <p>Your access to and use of leocorp.com ("the Website") and the Services outlined, are subject exclusively to these Terms and Conditions.</p>
            <p>You will not use the Website/Services for any purpose that is unlawful or prohibited by these Terms and Conditions. By using the Website/Services you are fully accepting the terms, conditions and disclaimers contained in this notice. If you do not accept these Terms and Conditions you must immediately stop using the Website/Services.</p>
            <p>LEOCORP reserves the right to update or amend these Terms and Conditions at any time without prior notice to its clients and your continued use of the Website/Services following any changes shall be deemed to be your acceptance of such change. It is therefore your responsibility to check the Terms and Conditions regularly for any changes.</p>
            <p class="flow-text">International Use</p>
            <p>You agree to comply with all applicable laws regarding the transmission of technical data exported from the United Kingdom or the country in which you reside (if different) and with all local laws and rules regarding acceptable use of and conduct on the Internet.</p>
            <p class="flow-text">Links to third party Websites</p>
            <p>The Website/Services may include links to third party websites that are controlled and maintained by others. Any link to other websites is not an endorsement of such websites and you acknowledge and agree that LEOCORP is not responsible for the content or availability of any such sites.</p>
            <p class="flow-text">Acceptable Uses</p>
            <p>You acknowledge that all information, text, graphics, logos, photographs, images, moving images, sound, illustrations and other materials ("the Content"), whether posted publicly or transmitted privately, are the sole responsibility of the person from whom such Content originated. LEOCORP does not control or endorse the Content and cannot guarantee the accuracy, integrity or quality of such Content and you acknowledge that by using the Services you may be exposed to content that is offensive and/or indecent. LEOCORP will not be liable in any way for any Content or for any loss or damage of any kind resulting from the use of any Content transmitted via the Services and you agree to bear all risks associated with the use of any Content, including any reliance on the accuracy or completeness of such Content.</p>
            <p>In using the Website/Services you agree not to:</p>

            <ol>
              <li>Use the Services to send junk email, spam, chain letters, pyramid schemes or any other unsolicited messages, commercial or otherwise.</li>
              <li>Post, publish, distribute or disseminate material or information that is defamatory, infringing, obscene, indecent, threatening, abusive, harassing or unlawful.</li>
              <li>Post, publish, distribute or disseminate material or information that incites discrimination, hate or violence towards any person or group on account of their race, religion, disability, nationality or otherwise.</li>
              <li>Threaten abuse, disrupt, stalk or otherwise violate the legal rights (including rights of privacy and publicity) of others.</li>
              <li>Violate any applicable laws or regulations.</li>
              <li>Use the Website/Services in any manner that could damage, disable, overburden or impair the Website/Services or interfere with any other party's use and enjoyment of the Website/Services.</li>
              <li>Post, publish, distribute or disseminate material or information that you do not have a right to transmit under any law or under contractual or fiduciary relationships (such as inside information or confidential information disclosed in the course of employment or under a confidentiality agreement)</li>
              <li>Attempt to gain unauthorised access to any of the Services, other accounts, computer systems or networks connected to the Website/Services through hacking, password mining or similar.</li>
              <li>Use any information or material in any manner that infringes any copyright, trademark, patent or other proprietary right of any party.</li>
              <li>Make available or upload files that contain a virus, worm, trojan or corrupt data that may damage the operation of the computer or property of another.</li>
              <li>Collect or store personal information about others, including email addresses.</li>
              <li>Advertise or offer to buy or sell goods or services for any commercial purpose, unless such communication facility specifically allows such messages.</li>
              <li>Impersonate any person or entity for the purpose of misleading others.</li>
            </ol>

            <p>LEOCORP has no obligation to monitor the Services but shall be entitled to review materials posted to a communications facility and, at its sole discretion, to remove any material that breaches these Terms and Conditions or is otherwise objectionable.</p>
            <p class="flow-text">User Account, Password and Security</p>
            <p>You will be required to complete the registration process by providing certain information and registering a username and password for use with the Services. You are responsible for maintaining the confidentiality of the username and password and also for all activities that take place under your account.</p>
            <p>You agree to immediately notify LEOCORP of any unauthorised use of your password or account or any other breach of security. In no event will the company be liable for any indirect or consequential loss or damage whatsoever resulting from the disclosure of your username and/or password. You may not use another person's account at any time, without the express permission of the account holder.</p>
            <p class="flow-text">Intellectual Property Rights</p>
            <p>The Website and its content (including without limitation the Website design, text, graphics and all software and source codes connected with the Website and the Services) are protected by copyright, trade marks, patents and other intellectual property rights and laws.</p>
            <p>In accessing the Website you agree that you will access the contents solely for your personal, non-commercial use. None of the content may be downloaded, copied, reproduced, transmitted, stored, sold or distributed without the prior written consent of the copyright holder. This excludes any downloading, copying and printing of pages of the Website for personal, non-commercial home use only.</p>
            <p>LEOCORP does not claim ownership of any materials you post, upload or submit to any publicly accessible area of the Services. However, by doing so you are granting LEOCORP a world-wide, royalty free, non-exclusive licence to copy, distribute, transmit, reproduce, publicly display, edit, translate or publish such Content for as long as you elect to display such Content via the Services. The licence shall be terminated when such Content is deleted from the Services.</p>
            <p class="flow-text">Termination</p>
            <p>LEOCORP has the right to terminate your access to any or all of the Services at any time, without notice, for any reason, including without limitation, breach of these Terms and Conditions. LEOCORP may also at any time, at its sole discretion, discontinue the Website/Services or any part thereof without prior notice and you agree that we shall not be liable to you or any third party for any termination of your access to the Website/Services.</p>
            <p class="flow-text">Indemnity</p>
            <p>You agree to indemnify and hold LEOCORP and its employees and agents harmless from and against any breach by you of these Terms and Conditions and any claim or demand brought against the company by any third party arising out of your use of the Services and/or any Content submitted, posted or transmitted through the Services, including without limitation, all claims, actions, proceedings, losses, liabilities, damages, costs, expenses (including reasonable legal costs and expenses) howsoever suffered or incurred by LEOCORP in consequence of your breach of these Terms and Conditions.</p>
            <p class="flow-text">Disclaimers and Limitation of Liability</p>
            <p>Use of the Website/Services is at your own risk. The Website/Services are provided on an "AS IS" and "AS AVAILABLE" basis without any representation or endorsement made and without warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</p>
            <p>To the extent permitted by law, LEOCORP will not be liable for any indirect or consequential loss or damage whatsoever (including without limitation loss of business, opportunity, data, profits) arising out of or in connection with the use of the Website/Services.</p>
            <p>LEOCORP makes no warranty that the Website/Services will meet your requirements, that the Content will be accurate or reliable, that the functionality of the Website/Services will be uninterrupted or error free, that defects will be corrected or that the Website/Services or the server that makes them available are free of viruses or anything else that may be harmful or destructive.</p>
            <p>Nothing in these Terms and Conditions shall be construed so as to exclude or limit the liability of LEOCORP for death or personal injury as a result of the negligence of LEOCORP or that of its employees or agents. Nothing in these Terms and Conditions shall affect your statutory rights as a consumer.</p>
            <p class="flow-text">Severance</p>
            <p>If any of these Terms and Conditions should be determined to be invalid, illegal or unenforceable for any reason by any court of competent jurisdiction then such Term or Condition shall be severed and the remaining Terms and Conditions shall survive and remain in full force and effect and continue to be binding and enforceable.</p>
            <p class="flow-text">Governing Law</p>
            <p>These Terms and Conditions shall be governed by and construed in accordance with the law of England and you hereby submit to the exclusive jurisdiction of the English courts.</p>
            <p class="flow-text">Company Information</p>
            <p>LEOCORP LIMITED, registered in England and Wales under company number 06709194, is an IT services company whose registered office is 85 Great Portland Street, London W1W 7LT</p>

          </article>
        </div>
      </div>
    </div>
  </section>
</template>