<template>
  <section class="bg-dark text-secondary">
    <div class="container" style="min-height: 100vh">
      <div class="row py-5">
        <div class="col-sm-12">
          <h3 class="display-5 text-uppercase text-secondary fw-bolder mb-5">
            Payment
          </h3>
          <table class="table table-dark" id="bank-id">
            <thead>
              <tr>
                <th>Payment</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Account name</th>
                <td>LEOCORP LIMITED</td>
              </tr>
              <tr>
                <th>Account number</th>
                <td>52094495</td>
              </tr>
              <tr>
                <th>Sort code</th>
                <td>40-02-44</td>
              </tr>
              <tr>
                <th>Bank address</th>
                <td>
                  <address>
                    HSBC PLC,<br>
                    Canada Place, Canary Wharf,<br>
                    London E14 5AH
                  </address>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<style>
  /** override bootstrap table color style below */
  #bank-id thead > tr > th, 
  #bank-id thead > tr > td, 
  #bank-id tbody > tr > th, 
  #bank-id tbody > tr > td { color: rgb(108 117 125)}
</style>