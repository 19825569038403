<script>
export default {
  name: 'App',
}
</script>
<script setup>
import HeaderComponent from './components/HeaderComponent.vue'
import FooterComponent from './components/FooterComponent.vue'

</script>

<!-- IMPORTANT: Bootstrap tables have a default margin-bottom: 1rem so if you notice extra spacing anywhere, inspect for a <table> in use -->

<template>
  <header-component></header-component>

  <main style="min-height:100vh;">
    <router-view></router-view>
  </main>
  
  <footer-component></footer-component>
</template>

<style>
  ::selection {
    color: white;
    background: red;
  }

  ::-moz-selection { /* Code for Firefox */
    color: white;
    background: red;
  }
  
  body {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #000;
  }

  h1,h2,h3,
  .h1,.h2,.h3 {
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  
  section {
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .ff-monospace {
    font-family: monospace;
  }

  .text-grey {
    color: #bbb!important;
  }

  .img-grey {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
</style>

