import { createApp } from 'vue'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import App from './App.vue'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
/* add icons to the library */
library.add(faUserSecret)

// leocorpldn web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBAf1bGB4Fsq35VQVmOkI0KoEST8tPmkfE",
  authDomain: "leocorpldn.firebaseapp.com",
  projectId: "leocorpldn",
  storageBucket: "leocorpldn.firebasestorage.app",
  messagingSenderId: "724227968115",
  appId: "1:724227968115:web:9052022c9b4a1931ceee06"
}

firebase.initializeApp(firebaseConfig);

let app;

firebase.auth().onAuthStateChanged(user => {
  if(!app) {
    console.log(user?.email.replace(user?.email, 'Hello there!'));
    
    app = createApp(App)
            .component('font-awesome-icon', FontAwesomeIcon)
            .use(router)
            .mount('#app');
  }
});