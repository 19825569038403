<script setup>
  const fullYear = new Date().getFullYear()
</script>

<template>
  <footer>
    <nav class="navbar navbar-light bg-black">
      <div class="container">
        <small class="text-secondary text-uppercase py-3">
            &copy; {{ fullYear }} <span class="text-light">Leo</span>Corp &middot;
          <span>
            <router-link to="/terms" class="text-secondary text-decoration-none">Terms</router-link>
          </span>
        </small>
      </div>
    </nav>
  </footer>
</template>
